import { tailwind } from "@theme-ui/preset-tailwind"

const theme = {
  ...tailwind,
  colors: {
    ...tailwind.colors,
    primary: "#ed8936",
    icons: "#ea580c",
  },
  zIndices: {
    ...tailwind.zIndices,
    appHeader: 7,
  },
  sizes: {
    ...tailwind.sizes,
    appHeader: [80, 80, 100, 100],
  },
  after: {
    middle: {
      display: "block",
      bg: "icons",
      height: "3px",
      mt: 0,
      mb: -13,
      left: "calc(50% - 10px)",
      content: "''",
      position: "absolute",
      bottom: 0,
      width: "20px",
    },
    start: {
      display: "block",
      bg: "icons",
      height: "3px",
      mt: 0,
      mb: -13,
      content: "''",
      position: "absolute",
      bottom: 0,
      width: "20px",
    },
  },
  headings: {
    subHeader: {
      position: "relative",
      "&:after": theme => theme.after.middle,
      m: 2,
      ml: "auto",
      mr: "auto",
      color: "gray.4",
      light: {
        position: "relative",
        "&:after": theme => theme.after.middle,
        m: 2,
        ml: "auto",
        mr: "auto",
        color: "gray.6",
      },
    },
  },
}

export default theme
